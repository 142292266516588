// LINKS
export const TERMS_OF_SERVICE_LINK = 'https://mytomorrows.com/en/terms-and-conditions';
export const TERMS_OF_USE_LINK = 'https://mytomorrows.com/terms-of-use-platform';
export const PRIVACY_POLICY_LINK = 'https://mytomorrows.com/privacy-statement/';
export const DATA_PROCESSING_ADDENDUM_LINK = 'https://mytomorrows.com/data-processing-addendum/';
export const COOKIE_STATEMENT_LINK = 'https://mytomorrows.com/cookie-statement/';
export const CONTACT_US_LINK = 'https://mytomorrows.com/contact-us/';
export const PATIENT_CONTACT_US_LINK = 'https://mytomorrows.com/contact-patients/';
export const LEGACY_HCP_PORTAL_LINK = 'https://mytomorrows.com/hcp-portal/en/home';

// STORAGE KEYS
export const REDIRECT_URL_STORAGE_KEY = 'MYT_REDIRECT_URL';

// Regex to accept spaces before, after and in between valid words, including Unicode characters.
export const VALID_WORDS_REGEX = /^\s*\p{L}+(?:\s+\p{L}+)*\s*$/u;
export const REGISTRATION_NUMBER_REGEX = '^[a-zA-Z0-9_-]+$';
export const NP_NUMBER_VALID_REGEX = /^NP[0-9]{1,18}$/;
export const EAP_NUMBER_VALID_REGEX = /^EAP[0-9]+$/;

// OTHERS
export const GA_EVENT = 'ga_event';
export const OTP_EXPIRED_FLAG = 'otp_expired';
export const TOKEN_EXPIRED_FLAG = 'token_expired';
export const EAP_JOURNEY_PHASE = 'eap_journey';
