import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  setItem<K>(key: string, value: K): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T {
    const value: string | null = sessionStorage.getItem(key);

    return value ? JSON.parse(value) : (null as T);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  hasItem(key: string): boolean {
    return sessionStorage.getItem(key) !== null;
  }
}
