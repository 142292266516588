@switch (true) {
  @case (type === buttonType.Basic) {
    <button
      mat-button
      [ngClass]="[buttonSizeClass, 'myt-button', customCssClasses]"
      [type]="buttonNativeType"
      [disabled]="disabled"
      (click)="buttonClick.emit()"
    >
      <ng-container *ngTemplateOutlet="loadingTmp" />
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </button>
  }
  @case (type === buttonType.Flat) {
    <button
      mat-flat-button
      [type]="buttonNativeType"
      [ngClass]="[buttonSizeClass, 'myt-button', customCssClasses]"
      [disabled]="disabled"
      (click)="buttonClick.emit()"
      [color]="btnColor"
    >
      <ng-container *ngTemplateOutlet="loadingTmp" />
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </button>
  }
  @case (type === buttonType.FlatLink) {
    <a
      mat-flat-button
      #anchorButton
      [ngClass]="[buttonSizeClass, 'myt-button', customCssClasses]"
      [disabled]="disabled"
      [color]="btnColor"
      [target]="linkTarget()"
      [routerLink]="routeConfig()?.routerLink"
      [queryParams]="routeConfig()?.queryParams"
    >
      <ng-container *ngTemplateOutlet="loadingTmp" />
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </a>
  }
  @case (type === buttonType.Stroked || type === buttonType.StrokedError || type === buttonType.StrokedWhite) {
    <button
      mat-stroked-button
      class="myt-button {{ buttonSizeClass }} {{ customCssClasses }}"
      [type]="buttonNativeType"
      [ngClass]="{
        'myt-button--stroked-white': type === buttonType.StrokedWhite,
        'myt-button--stroked-error': type === buttonType.StrokedError
      }"
      [disabled]="disabled"
      (click)="buttonClick.emit()"
    >
      <ng-container *ngTemplateOutlet="loadingTmp" />
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </button>
  }
  @case (type === buttonType.StrokedLink) {
    <a
      mat-stroked-button
      #anchorButton
      class="myt-button {{ buttonSizeClass }} {{ customCssClasses }}"
      [disabled]="disabled"
      [target]="linkTarget()"
      href="{{ routeConfig()?.href || 'js:void(0)' }}"
      [routerLink]="routeConfig()?.routerLink"
      [queryParams]="routeConfig()?.queryParams"
    >
      <ng-container *ngTemplateOutlet="loadingTmp" />
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </a>
  }
  @case (type === buttonType.Icon) {
    <button
      mat-icon-button
      [attr.type]="buttonNativeType"
      [ngClass]="[buttonSizeClass, 'myt-button', customCssClasses]"
      [disabled]="disabled"
      (click)="buttonClick.emit()"
      [color]="btnColor"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </button>
  }
  @case (type === buttonType.FabMiniIcon) {
    <button
      mat-mini-fab
      [type]="buttonNativeType"
      [ngClass]="[buttonSizeClass, 'myt-button', 'myt-button--fab-mini', disableShadow ? 'shadow-none' : '', customCssClasses]"
      [disabled]="disabled"
      [color]="btnColor"
      (click)="buttonClick.emit()"
    >
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </button>
  }
  @case (type === buttonType.Link) {
    <a [ngClass]="[buttonSizeClass, 'myt-button-link flex', customCssClasses]" [class.disabled]="disabled" (click)="buttonClick.emit()">
      <ng-container *ngTemplateOutlet="buttonTemplate" />
    </a>
  }
}

<ng-template #buttonTemplate>
  <ng-content />
  {{ text }}
</ng-template>

<ng-template #loadingTmp>
  @if (loading()) {
    <myt-spinner class="mr-2" [strokeWidth]="2" [diameter]="20" color="neutral" />
  }
</ng-template>
