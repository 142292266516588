import { Injectable } from '@angular/core';
import { fromEvent, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieConsent } from './cookie-consent.interface';

/**
 * Service to track current cookie consent of the user based on CookieFirst script
 */
@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  private _consent: Observable<CookieConsent | null> = of(null).pipe(
    switchMap(() => ((window as any).CookieFirst ? of(null) : fromEvent<CustomEvent<CookieConsent>>(window, 'cf_init'))),
    switchMap(() =>
      fromEvent<CustomEvent<CookieConsent>>(window, 'cf_consent').pipe(
        map((event: CustomEvent<CookieConsent | null> | null) => event?.detail ?? null),
        startWith((window as any).CookieFirst.consent),
      ),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /**
   * @return Returns Observable with current user consent or null if no consent preference were given. Emits new values when consent changes.s
   */
  public get consent$() {
    return this._consent;
  }

  /**
   * Removes cookie from the document by setting expiration date in the past.
   * @param cookieName name of the cookie to be removed
   */
  public removeCookie(cookieName: string): void {
    document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
