import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { ButtonSize } from '../button/button-size.enum';
import { ButtonType } from '../button/button-type.enum';
import { ButtonsComponent } from '../button/button.component';
import { ChipType } from '../chip/chip-type.enum';
import { ChipComponent } from '../chip/chip.component';
import { IconSize } from '../icon/icon-size.enum';
import { IconsComponent } from '../icon/icon.component';
import { MenuItem } from './menu.interface';

@Component({
  selector: 'myt-menu',
  standalone: true,
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuModule, ButtonsComponent, IconsComponent, RouterLink, NgClass, ChipComponent],
})
export class MenuComponent {
  readonly itemSelect = output<MenuItem['label']>();
  readonly menuClosed = output<void>();
  readonly menuOpened = output<void>();
  readonly positionX = input<'before' | 'after'>('before');

  readonly buttonTypes = ButtonType;
  readonly buttonType = ButtonType.StrokedWhite;
  readonly buttonSize = ButtonSize.Medium;
  readonly iconSize = IconSize;
  readonly chipType = ChipType;

  readonly $buttonLabel = input<string>('', { alias: 'buttonLabel' });
  readonly $textLabel = input<string>('', { alias: 'textLabel' });
  readonly $items = input.required<MenuItem[]>({ alias: 'items' });
  readonly $labelCssClasses = input<string>('', { alias: 'labelCssClasses' });
  readonly $menuPanelCssClasses = input<string>('', { alias: 'menuPanelCssClasses' });
  readonly $menuItemCssClasses = input<string>('', { alias: 'menuItemCssClasses' });

  onItemSelect(label: MenuItem['label']): void {
    this.itemSelect.emit(label);
  }
}
