import { Inject, Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieConsent } from '../cookie-consent/cookie-consent.interface';
import { CookieConsentService } from '../cookie-consent/cookie-consent.service';

const DATADOG_COOKIE_NAME = '_dd_s';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  constructor(
    private readonly cookieConsentService: CookieConsentService,
    @Inject(ENVIRONMENT) private readonly environment: Environment,
  ) {}

  /**
   * Initialize Datadog analytics if the necessary cookie consent is given.
   * @return Returns Observable<boolean> that emits true if the datadog initialization has been invoked, otherwise false.
   */
  public initializeDatadog(): Observable<boolean> {
    if (!this.environment.datadogClientToken || !this.environment.datadogApplicationId) {
      return of(false);
    }

    this.startDatadogAnalytics();

    return this.cookieConsentService.consent$.pipe(
      map((consent: CookieConsent | null) => {
        if (consent?.performance) {
          datadogRum.setTrackingConsent('granted');

          return true;
        }

        datadogRum.setTrackingConsent('not-granted');
        this.removeDatadogCookie();

        return false;
      }),
    );
  }

  private startDatadogAnalytics(): void {
    datadogRum.init({
      clientToken: this.environment.datadogClientToken,
      applicationId: this.environment.datadogApplicationId,
      site: 'datadoghq.eu',
      service: 'platform-web',
      env: this.environment.production ? 'production' : 'develop',
      allowedTracingUrls: [],
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0,
      defaultPrivacyLevel: 'mask',
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackingConsent: 'not-granted',
    });
  }

  // Since DataDog doesn't support dynamic user consent tracking, we need to remove the cookie manually.
  // See https://github.com/DataDog/browser-sdk/issues/2923
  private removeDatadogCookie(): void {
    this.cookieConsentService.removeCookie(DATADOG_COOKIE_NAME);
  }
}
