<nav class="platform-navbar bg-primary-950 relative z-50 flex items-center justify-between px-6">
  <div class="relative flex h-full w-full flex-row items-center justify-between lg:w-fit lg:justify-start">
    <a routerLink="/">
      <img ngSrc="assets/images/logo-myTomorrows.svg" width="160" height="20" [alt]="'PLATFORM.LOGO' | translate" />
    </a>

    <div class="bottom-0 top-0 mx-4 hidden h-16 w-[1px] bg-white opacity-20 lg:block"></div>
    <div class="hidden lg:block">
      <ng-container *ngTemplateOutlet="navList" />
    </div>

    <div class="lg:hidden">
      <ng-container *ngTemplateOutlet="hamburgerIcon" />
    </div>
  </div>

  <div class="hidden flex-row lg:flex">
    @if ($user().userType === userType.search) {
      <ng-container *ngTemplateOutlet="preLoginTemplate" />
    } @else {
      <ng-container *ngTemplateOutlet="postLoginTemplate" />
    }
  </div>
</nav>

<!-- (Mobile) expand panel -->
@if ($mobileExpanded()) {
  <div class="platform-navbar-backdrop absolute inset-x-0 z-10 min-h-full bg-black opacity-50 lg:hidden" (click)="toggleNavbar()"></div>
  <div class="platform-navbar-expandable-panel bg-primary-950 absolute inset-x-0 z-20 w-full pb-10 pt-7 text-center lg:hidden">
    <ng-container *ngTemplateOutlet="navList" />
    <div class="flex flex-col">
      @if ($user().userType === userType.search) {
        <div class="flex flex-col space-y-4">
          <ng-container *ngTemplateOutlet="preLoginTemplate" />
        </div>
      } @else {
        <div class="border-primary-900 mx-auto mt-8 w-52 rounded border border-solid">
          <div class="mx-auto flex flex-row justify-center py-3">
            <myt-icon [name]="$avatarIcon()" [size]="iconSize.Small" class="text-primary-200 platform-navbar__avatar-icon mr-2" />
            <span class="text-regular my-auto truncate font-medium text-neutral-200">{{ $user().firstname + ' ' + $user().lastname }}</span>
          </div>
          <div class="bg-primary-900 h-[1px] w-full"></div>
          <ul class="list-none space-y-6 p-4 font-normal">
            @for (item of $userDropdownMenu(); track item.label) {
              <li>
                @if (item.link) {
                  <a [routerLink]="item.link" class="text-neutral-100 no-underline hover:text-white" (click)="$mobileExpanded.set(false)">{{
                    item.label
                  }}</a>
                } @else {
                  <a
                    href="javascript:void(0)"
                    (click)="onUserMenuItemSelect(item.label)"
                    class="text-neutral-100 no-underline hover:text-white"
                    >{{ item.label }}</a
                  >
                }
              </li>
            }
          </ul>
        </div>
      }
    </div>
  </div>
}

<!-- (Mobile and desktop) User/login menu (pre login) -->
<ng-template #preLoginTemplate>
  <div
    class="platform-navbar--menu-item text-regular mx-auto box-border cursor-pointer rounded-lg px-4 py-2 font-medium text-neutral-200 hover:text-white lg:mx-1 lg:font-semibold"
    (click)="navigateTo('login')"
    role="nav"
  >
    <a>{{ 'PLATFORM.HEADER.LOGIN' | translate }}</a>
  </div>
  <div
    class="border-primary-200 text-regular text-primary-200 hover:text-primary-950 hover:bg-primary-200 mx-auto cursor-pointer rounded-lg border border-solid px-4 py-2 font-semibold lg:mx-1"
    (click)="navigateTo('signup')"
    role="nav"
  >
    <a>{{ 'PLATFORM.HEADER.CREATE_ACCOUNT' | translate }}</a>
  </div>
</ng-template>

<!-- (Desktop) User/login menu (post login) -->
<ng-template #postLoginTemplate>
  <myt-menu
    #userMenu
    [items]="$userDropdownMenu()"
    menuPanelCssClasses="!w-48 platform-navbar__menu-panel !text-sm"
    (itemSelect)="onUserMenuItemSelect($event)"
  >
    <myt-button
      customMenuButton
      [type]="buttonType.StrokedWhite"
      [size]="buttonSize.Medium"
      class="flex flex-row justify-between"
      [customCssClasses]="'!px-2 platform-navbar__menu-button !w-48'"
    >
      <myt-icon [name]="$avatarIcon()" [size]="iconSize.Small" class="text-primary-200 platform-navbar__avatar-icon mr-2" />
      <span class="text-regular w-full truncate font-medium">{{ $user().firstname + ' ' + $user().lastname }}</span>
      <myt-icon name="keyboard_arrow_down" class="platform-navbar__expand-icon ml-2 text-white" [size]="iconSize.ExtraSmall" />
    </myt-button>
  </myt-menu>
</ng-template>

<!-- (Mobile and desktop) Navigation list -->
<ng-template #navList>
  <ul class="lg:text-regular mx-auto flex flex-1 list-none flex-col gap-3 text-lg text-neutral-200 lg:flex-row lg:gap-2">
    @for (item of $navItems(); track item.route) {
      @if (!item.hide) {
        <li
          class="platform-navbar--menu-item text-regular mx-auto box-border cursor-pointer rounded-lg px-4 py-2 font-medium hover:text-white lg:mx-1 lg:font-semibold"
          [routerLinkActive]="['lg:bg-primary-800', 'lg:text-white', 'active']"
          [routerLink]="[item.route]"
          (click)="toggleNavbar()"
        >
          <a>{{ item.title }}</a>
        </li>
      }
    }
  </ul>
</ng-template>

<!-- Hamburger menu icon -->
<ng-template #hamburgerIcon>
  <myt-button
    class="my-auto ml-auto flex"
    [type]="buttonType.Icon"
    [size]="buttonSize.Large"
    (buttonClick)="toggleNavbar()"
    [customCssClasses]="'flex my-auto !p-0'"
  >
    <myt-icon
      [name]="$mobileExpanded() ? 'close' : 'menu'"
      class="platform-navbar__expand-icon flex text-white"
      [size]="iconSize.MediumSmall"
    />
  </myt-button>
</ng-template>
