import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SsoService } from '@my-tomorrows/api';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { CURRENT_MS_TOKEN_STORAGE_KEY } from '@my-tomorrows/shared-data';
import { from, switchMap } from 'rxjs';

export const msalAuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const msalService = inject(MsalService);
  const ssoService = inject(SsoService);
  const environment: Environment = inject(ENVIRONMENT);

  const apiSsoPaths: string[] = ['eap'];

  const currentAccount = msalService.instance?.getAllAccounts()?.[0] || undefined;
  const currentUsedToken: string | null = localStorage.getItem(CURRENT_MS_TOKEN_STORAGE_KEY);

  if (!apiSsoPaths.some((path) => req.url.includes(path))) {
    return next(req);
  }

  return from(msalService.instance.acquireTokenSilent({ scopes: [`${environment.msal.clientId}/.default`], account: currentAccount })).pipe(
    switchMap(({ idToken: newToken }) => {
      if (currentUsedToken !== newToken) {
        localStorage.setItem(CURRENT_MS_TOKEN_STORAGE_KEY, newToken);

        return ssoService.validateMicrosoftToken(newToken).pipe(
          switchMap(() => {
            const authReq = req.clone({
              withCredentials: true,
            });
            return next(authReq);
          }),
        );
      } else {
        return next(req);
      }
    }),
  );
};
