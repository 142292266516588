export enum ChipType {
  Default = 'default',
  Primary = 'primary',
  Accent = 'accent',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Neutral = 'neutral',
  Badge = 'badge',
}
