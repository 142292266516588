export enum PostHogEvents {
  PageView = '$pageview',
  ReferralAccepted = 'referral:accept_referral_button_click',
  ReferralRejected = 'referral:reject_referral_button_click',
  ReferralInitiated = 'referral:submit_referral_button_click',
  ReferralClickToMessageView = 'referral:move_to_message_view_on_initiate',
  ReferralClickToPatientInfoFormView = 'referral:move_to_patient_details_view_on_initiate',
  ReferralRequestAccess = 'referral:click_on_request_access_button',
  ReferralInitiateButton = 'referral:click_on_contact_site_button',
  ReferralRequestAccessWithSignup = 'referral:signup_with_request_access',
  ReferralRequestAccessWithLogin = 'referral:login_with_request_access',
  AccountCreated = 'account_creation:verify_account_button_click',
  AccountActivated = 'account_creation:activate_account_button_click',
  AccountLogin = 'account_login:login_button_click',
  TrialSearchConducted = 'trial_search:start_search_button_click',
  TrialPrescreeningConducted = 'trial_search:start_prescreening_button_click',
}

export enum PostHogUserRoles {
  Patient = 'patient', // or caregivers
  Hcp = 'hcp',
  HcpCrtMember = 'hcpCrtMember',
  SiteManager = 'siteManager',
  MytUser = 'mytUser',
  Anonymous = 'anonymous',
}

/**
 * Additional properties for posthog event.
 * User snake_case to align with posthog recommendation
 */
export interface PostHogAdditionalProperties {
  api_response?: 'success' | 'failure';
  is_internal_user?: boolean;
  user_role?: PostHogUserRoles;
  submission_type?: string;
  has_fail_validation?: boolean;
}
