import { bootstrapApplication } from '@angular/platform-browser';

import { appConfig } from './app/app.config';

import { AppComponent } from './app/app.component';

import { initializePosthog } from './posthog-init';

initializePosthog();

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
